<template>
  <div>
    <v-menu bottom origin="center center" transition="scale-transition" min-width="400" max-height="500" tile>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="getItems" icon v-bind="attrs" v-on="on" :loading="loading">
          <v-badge :value="items.filter(x => !x.seen).length" dark :content="items.filter(x => !x.seen).length" color="error" bordered overlap grow>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <div v-if="items.length == 0">
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <h3>
                {{ $t('no-data') }}
              </h3>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>

      <div v-if="items.length != 0">
        <v-list v-for="(item, i) in items" :key="i" style="padding: 0px" color="grey lighten-3 rounded">
          <template v-if="item != undefined">
            <v-list-item @click="seen(item, i)" style="padding: 0px" :class="item.seen ? 'white' : ''">
              <v-list-item-title>
                <v-alert class="transparent" border="left" tile colored-border="" color="transparent">
                  <v-row align="center" class="ma-1">
                    <h4 class="primary--text">
                      {{ title(item).title }}
                    </h4>
                    <v-spacer />
                    <div dir="ltr" class="grey--text" style="font-size: 10px;">
                      {{ item.created | formatArDateAgo }}
                    </div>
                  </v-row>
                  <div class="mt-2 mr-1 text--darken-2 grey--text" style="font-size: 14px;">
                    {{ title(item).body }}
                  </div>
                </v-alert>
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-divider />
        </v-list>

        <!-- <infinite-loading
          direction="bottom"
          @infinite="infiniteHandler"
        ></infinite-loading> -->
      </div>
      <!-- <v-card> -->
      <!-- </v-card> -->

      <!-- </div> -->
    </v-menu>
  </div>
</template>

<script>
// import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    // InfiniteLoading,
  },
  data: () => ({
    loading: false,
    length: 0,
    filter: {
      pageSize: 50,
      pageIndex: 1,
    },
    items: [],
  }),

  created() {
    this.getItems();
  },
  mounted() {
    this.$eventBus.$on('refresh-notifications', () => {
      this.getItems();
    });
    this.$eventBus.$on('seen', (permitId) => {
      if (this.items.find((x) => x.permitId == permitId) != undefined) {
        this.seen(
          this.items.find((x) => x.permitId == permitId),
          this.items.findIndex((x) => x.permitId == permitId),
        );
      }
    });
    this.$eventBus.$on('seenOrder', (orderId) => {
      if (this.items.find((x) => x.orderId == orderId) != undefined) {
        console.log('yrt');
        this.seen(
          this.items.find((x) => x.orderId == orderId),
          this.items.findIndex((x) => x.orderId == orderId),
        );
      }
    });
  },

  beforeDestroy() {
    this.$eventBus.$off('refresh-notifications');
    // this.$eventBus.$off("seen")
  },

  methods: {
    title(item) {
      let additional = '';
      let title = '';
      switch (item.type) {
        case 1:
          additional = this.$t('ldyk-maamlh-jdydh-banwan') + ' ' + item.title;
          title = this.$t('maamlh-jdydh');
          break;
        case 2:
          additional = this.$t('ldyk-tsryh-amny-jdyd-banwan') + ' ' + item.title;
          title = this.$t('tsryh-amny-jdyd');
          break;
        case 3:
          additional = this.$t('tm-injaz-altkhwyl-althy-banwan') + ' ' + item.title;
          title = this.$t('injaz-altkhwyl');
          break;
        case 4:
          additional = this.$t('tm-rfdh-almaamlh-alkhash-bk') + ' ' + item.title;
          title = this.$t('thdythat-almaamlh');
          break;
        case 5:
          additional = this.$t('tm-ahalh-alktab') + ' ' + item.title;
          title = this.$t('ahalh-ktab');
          break;
        case 6:
          additional = this.$t('tm-arsal-rsalh-jdydh-banwan') + ' ' + item.title;
          title = this.$t('rsalh-jdydh');

          break;
        default:
          additional = 'انقر لمشاهده التفاصيل';
          title = 'اشعار جديد';
          break;
      }
      // item.body = additional;

      return {
        ...item,
        title: title,
        body: additional,
      };
    },

    async getItems(isInfinite = false) {
      this.loading = true;
      isInfinite = false;
      try {
        console.log('this.$store.state.user.user');
        console.log(this.$store.state.user.user);
        let GroupIds = [];
        if (this.$store.state.user.user.userGroups.length > 0) {
          await this.$store.state.user.user.userGroups.forEach((element) => {
            GroupIds.push(element.groupId);
          });
        }

        let type = this.getRoleType();
        let typeStr = '';
        for (let i = 0; i < type.length; i++) {
          typeStr += `&Types=${type[i]}`;
        }
        let url = '';
        if (this.$store.state.user.role == 'Guest') {
          url = `Notification/Guest?PageIndex=${this.filter.pageIndex}&PageSize=${this.filter.pageSize}&Sort=desc`;
        } else {
          url = `Notification?PageIndex=${this.filter.pageIndex}&PageSize=${this.filter.pageSize}&OrganizationId=${this.$store.state.user.role == 'Benefit' ? this.$store.state.user.user.organization.id : ''}&Sort=desc`;
        }
        if (this.$store.state.user.role != 'Benefit' && this.$store.state.user.role != 'Guest') {
          for (let j = 0; j < GroupIds.length; j++) {
            url = url + `&GroupIds=${GroupIds[j]}`;
          }
        }
        if (this.$store.state.user.role == 'Guest') {
          url += '&UserId=' + this.$store.state.user.user.id;
        }

        const res = await this.$http.get(url);
        if (isInfinite) {
          console.log('test');
          this.items = this.items.concat(res.data.items);
        } else {
          this.items = res.data.result;
        }
        this.length = res.data.count;
        console.log(this.items);
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.loading = false;
      }
    },

    findNestedObj(entireObj, keyToFind, valToFind) {
      let foundObj;
      JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
          foundObj = nestedValue;
        }
        return nestedValue;
      });
      return foundObj;
    },

    seen(item, i) {
      this.$http
        .put(`Notification/seen/${item.id}`)
        .then((res) => {
          this.items.find((x) => x.id == item.id).seen = true;
          this.items.splice(i, 1);
          if (item.type == 1) {
            this.$router.push('/orderNode/' + item.orderId + '/' + item.title);
          }
          if (item.type == 2) {
            this.$router.push('/permitStep/' + item.permitId + '/' + item.title);
          }
          if (item.type == 3) {
            this.$router.push('/permitStep/' + item.permitId + '/' + item.title);
          }
          if (item.type == 5) {
            this.$router.push('/document/' + item.documentId);
          }
          if (item.type == 4) {
            this.$router.push('/guest');
          }
          if (item.type == 6) {
            this.$router.push('/emails');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRoleType() {
      switch (this.$store.state.user.role) {
        case 'Employee':
          return [1, 4, 3, 5, 6];
        case 'Security':
          return [2, 6];
        case 'Benefit':
          return [3, 6];

        default:
          return [1, 4, 3, 5, 6];
        // break;
      }
    },

    infiniteHandler($state) {
      console.log('infiniteHandler');
      this.filter.pageIndex += 1;
      this.getItems(true);
    },
  },
};
</script>
